define("da-vinci/components/parasut-company-setting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showCompanySettingAsDefaultForPortal}}
    <ParasutCompanySetting::UpdateParasutCompanySettingStep
      @onCancel={{onCancel}}
      @itemClicked={{action "itemClicked"}}
    />
  {{/if}}
  {{#if showDestroyCompanyAsDefaultForParasut}}
    <ParasutCompanySetting::DestroyCompanyLinkStep
      @onCancel={{onCancel}}
      @itemClicked={{action "itemClicked"}}
    />
  {{/if}}
  
  */
  {
    "id": "Ql4HjE9M",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showCompanySettingAsDefaultForPortal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"parasut-company-setting/update-parasut-company-setting-step\",[],[[\"@onCancel\",\"@itemClicked\"],[[22,\"onCancel\"],[28,\"action\",[[23,0,[]],\"itemClicked\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showDestroyCompanyAsDefaultForParasut\"]]],null,{\"statements\":[[0,\"  \"],[5,\"parasut-company-setting/destroy-company-link-step\",[],[[\"@onCancel\",\"@itemClicked\"],[[22,\"onCancel\"],[28,\"action\",[[23,0,[]],\"itemClicked\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/parasut-company-setting/template.hbs"
    }
  });

  _exports.default = _default;
});