define("da-vinci/components/parasut-company-setting/navigation-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable template-length }}
  <ul
      data-test-opening-mode
    >
    {{#if isPortal}}
      <li class="showCompanySettingAsDefaultForPortal">
        <a
          href="#"
          data-test-company-setting
          {{action onClick "showCompanySettingAsDefaultForPortal"}}
  
        >
          {{t "parasutCompanySetting.connectionSetting" htmlSafe=true}}
        </a>
      </li>
    {{/if}}
    <li class="showDestroyCompanyAsDefaultForParasut">
      <a
        href="#"
        data-test-destroy-company
        {{action onClick "showDestroyCompanyAsDefaultForParasut"}}
      >
        {{t "parasutCompanySetting.connectionRemove" htmlSafe=true}}
  
      </a>
    </li>
  </ul>
  
  */
  {
    "id": "t+r4kl/P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isPortal\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"showCompanySettingAsDefaultForPortal\"],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],[24,[\"onClick\"]],\"showCompanySettingAsDefaultForPortal\"]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"parasutCompanySetting.connectionSetting\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"li\",true],[10,\"class\",\"showDestroyCompanyAsDefaultForParasut\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],[24,[\"onClick\"]],\"showDestroyCompanyAsDefaultForParasut\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"parasutCompanySetting.connectionRemove\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/parasut-company-setting/navigation-bar/template.hbs"
    }
  });

  _exports.default = _default;
});